import React from "react"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import Layout from "../components/layout"
import Head from "../components/head"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(15, 0, 12),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(9, 0, 4),
    },
  },
  heroGridContainer: {
    display: "grid",
    gridGap: theme.spacing(6),
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  heroImage: {
    width: "100%",
  },
  heroGridItemImage: {
    textAlign: "center",
  },
  bottomMargin: {
    marginBottom: theme.spacing(6),
  },
  logos: {
    width: "80px",
  },
  iconWrap: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  contactDetail: {
    margin: theme.spacing(7, 0),
  },
}))

const PrivacyPolicy = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Head title="Privacy Policy" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Privacy Policy
            </Typography>
          </Container>

          <Container maxWidth="md">
            <Typography component="p" paragraph>
              Janice Vu & Associates is committed to providing you with the best
              possible customer service experience. Janice Vu & Associates is
              bound by the Privacy Act 1988 (Crh), which sets out a number of
              principles concerning the privacy of individuals.
            </Typography>
            <Typography component="h3" variant="h4" gutterBottom>
              Collection of your personal information
            </Typography>
            <Typography component="p" paragraph>
              There are many aspects of the site which can be viewed without
              providing personal information, however, for access to future
              Janice Vu & Associates customer support features may be required
              to submit personally identifiable information. This may include
              but not limited to a unique username and password, or provide
              sensitive information in the recovery of your lost password.
            </Typography>
            <Typography component="h3" variant="h4" gutterBottom>
              Sharing of your personal information
            </Typography>
            <Typography component="p" paragraph>
              We may occasionally hire other companies to provide services on
              our behalf, including but not limited to handling customer support
              enquiries, processing transactions or customer freight shipping.
              Those companies will be permitted to obtain only the personal
              information they need to deliver the service. Janice Vu &
              Associates takes reasonable steps to ensure that these
              organisations are bound by confidentiality and privacy obligations
              in relation to the protection of your personal information.
            </Typography>

            <Typography component="h3" variant="h4" gutterBottom>
              Use of your personal information
            </Typography>
            <Typography component="p" paragraph>
              For each visitor to reach the site, we expressively collect the
              following non-personally identifiable information, including but
              not limited to browser type, version and language, operating
              system, pages viewed while browsing the Site, page access times
              and referring website address. This collected information is used
              solely internally for the purpose of gauging visitor traffic,
              trends and delivering personalized content to you while you are at
              this Site. From time to time, we may use customer information for
              new, unanticipated uses not previously disclosed in our privacy
              notice. If our information practices change at some time in the
              future we will use for these new purposes only, data collected
              from the time of the policy change forward will adhere to our
              updated practices.
            </Typography>
            <Typography component="h3" variant="h4" gutterBottom>
              Changes to this Privacy Policy
            </Typography>
            <Typography component="p" paragraph>
              Janice Vu & Associates reserves the right to make amendments to
              this Privacy Policy at any time. If you have objections to the
              Privacy Policy, you should not access or use the Site.
            </Typography>
            <Typography component="h3" variant="h4" gutterBottom>
              Accessing Your Personal Information
            </Typography>
            <Typography component="p" paragraph>
              You have a right to access your personal information, subject to
              exceptions allowed by law. If you would like to do so, please let
              us know. You may be required to put your request in writing for
              security reasons. Janice Vu & Associates reserves the right to
              charge a fee for searching for, and providing access to, your
              information on a per request basis.
            </Typography>
            <Typography component="h3" variant="h4" gutterBottom>
              Contacting Us
            </Typography>
            <Typography component="p" paragraph>
              Janice Vu & Associates welcomes your comments regarding this
              Privacy Policy. If you have any questions about this Privacy
              Policy and would like further information, please contact us
              during business hours Monday to Friday.
            </Typography>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

export default PrivacyPolicy
